import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Swiper from 'react-id-swiper';
import './styles.scss';

const ItemCarousel = ({ photos, path, photosCount, scrollTo }) => {
  const { t } = useTranslation();
  const [swiper, updateSwiper] = useState(null);

  const thumbnail = photos.map((sub, subindex) => {
    const showMore =
      photosCount > photos.length && subindex === photos.length - 1 ? (
        <a key={subindex} className="image-link" href={path}>
          <img src={sub.thumbnail} alt="" />
          <div className="show-more">
            {t('cards.item_card.show_more', { count: photosCount })}
          </div>
        </a>
      ) : (
        <a key={subindex} className="image-link" href={path}>
          <img src={sub.thumbnail} alt="" />
        </a>
      );

    return showMore;
  });
  if (scrollTo === 'next' && swiper !== null) {
    swiper.slideNext();
    updateSwiper(null);
  } else if (scrollTo === 'prev' && swiper !== null) {
    swiper.slidePrev();
    updateSwiper(null);
  }
  const params = {
    slidesPerView: 1,
    loop: true,
    lazy: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  };

  const images = (
    <Swiper {...params} getSwiper={updateSwiper}>
      {thumbnail}
    </Swiper>
  );

  return images;
};

ItemCarousel.propTypes = {
  photos: PropTypes.array,
  photosCount: PropTypes.number,
  path: PropTypes.string,
  scrollTo: PropTypes.string
};

export default ItemCarousel;
