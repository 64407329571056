import React, { Component } from 'react';
import classNames from 'classnames';
import { graphql } from 'react-apollo';
import flowRight from 'lodash.flowright';
import PropTypes from 'prop-types';
import {
  bookmarksAllQuery,
  deleteBookmarkQuery,
  createBookmarkQuery
} from '../../queries';
import Bugsnag from '../../utils/bugsnag';
import './styles.scss';

const createBookmarkGraphql = graphql(createBookmarkQuery, {
  name: 'addBookmark'
});
const deleteBookmarkGraphql = graphql(deleteBookmarkQuery, {
  name: 'deleteBookmark',
  options: props => ({
    update: store => {
      try {
        const bookmarksAll = store.readQuery({
          // https://github.com/apollographql/apollo-client/issues/1542#issue-219257573
          query: bookmarksAllQuery
        });
        store.writeQuery({
          query: bookmarksAllQuery,
          data: {
            bookmarks: bookmarksAll.bookmarks.filter(
              bookmark => bookmark.item.id !== props.itemId
            )
          }
        });
      } catch (e) {
        Bugsnag.notify(e);
      }
    }
  })
});

class Bookmark extends Component {
  constructor(...props) {
    super(...props);
    this.state = { isActive: this.props.isActive };
  }

  static propTypes = {
    itemId: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    removeItemFunc: PropTypes.func,
    addBookmark: PropTypes.func,
    deleteBookmark: PropTypes.func
  };

  toggleBookmark = e => {
    const { isActive } = this.state;
    const { addBookmark, deleteBookmark } = this.props;
    const action = !isActive ? addBookmark : deleteBookmark;

    e.preventDefault();

    this.setState(previousState => {
      action();
      return {
        isActive: !previousState.isActive
      };
    });
  };

  render() {
    const { itemId } = this.props;
    const { isActive } = this.state;

    return (
      <div
        className={classNames('bookmarking', 'card_view', { added: isActive })}
      >
        <a
          data-disable="true"
          className={classNames(isActive ? 'remove_bookmark' : 'add_bookmark')}
          data-cy="bookmark-icon"
          data-remote="true"
          data-method="post"
          href={`/bookmarks?card_view=true&amp;item_id=${itemId}`}
          onClick={this.toggleBookmark}
        />
      </div>
    );
  }
}

Bookmark.defaultProps = {
  isActive: false
};

export default flowRight(
  createBookmarkGraphql,
  deleteBookmarkGraphql
)(Bookmark);
